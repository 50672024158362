/**
 * @fileoverview This file is autogenerated - do not change it manually
 * You can update the jha-icons by running the following command in Banno Online:
 *    gulp regenerate-icons
 */
import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaIconSearchElement = class extends PolymerElement {
  static get is() {
    return 'jha-icon-search';
  }
  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 24px;
        height: 24px;
        fill: var(--jha-icon-color, var(--jha-text-base), #000000);
      }
      svg {
        width: 100%;
        height: 100%;
        display: block;
      }
    </style>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M20.78,19.72l-4.65-4.65a7.26,7.26,0,1,0-1.06,1.06l4.65,4.65a.75.75,0,0,0,1.06-1.06Zm-16-9.22a5.75,5.75,0,1,1,5.75,5.75A5.76,5.76,0,0,1,4.75,10.5Z"/>
    </svg>
  `;
  }
};

customElements.define(window.JhaIconSearchElement.is,
    window.JhaIconSearchElement);
export default window.JhaIconSearchElement;
